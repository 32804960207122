
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { NOT_EMPTY_BARCODE } from '@/processes/common/rules';

export default defineComponent({
  name: 'Input',
  props: {
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    isShowError: { type: Boolean, default: false },
    callback: { type: Function, default: () => null },
    placeholder: { type: String, default: '' },
    validations: { type: Object, default: () => ({ ...NOT_EMPTY_BARCODE }) },
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale, v$: useVuelidate() };
  },
  data() {
    return {
      isDirty: false, // Флаг который отвечает, нажимали ли на input
      value: '',
      placeholderValue: this.t(this.placeholder),
    };
  },
  methods: {
    changeIsDirty() {
      this.isDirty = true;
    },
    validate() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        const errors = this.v$.value.$errors.map(({ $message: message }) => ({ message }));
        this.$emit('onError', errors);
        return;
      }
      console.log('валидный');
    }
  },
  validations() {
    return {
      value: this.validations,
    };
  },
});
