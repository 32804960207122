import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section" }
const _hoisted_2 = { class: "section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        text: "Кнопка без типа",
        onClick: _ctx.logBtn
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        text: "Кнопка с типом default",
        type: "default"
      }),
      _createVNode(_component_Button, {
        text: "Кнопка с типом error",
        type: "error"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Input, {
        name: "test-input",
        placeholder: "scan_barcode",
        callback: () => _ctx.alert(123),
        validations: _ctx.validatorBarcode,
        onOnError: _ctx.logError
      }, null, 8, ["callback", "validations", "onOnError"])
    ])
  ]))
}