import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-section" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "error-msg" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["placeholder", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isShowError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.value.$errors, (error, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-errors",
              key: index
            }, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(error.$message), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: _ctx.name
        }, _toDisplayString(this.label), 9, _hoisted_4))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      placeholder: _ctx.placeholderValue,
      name: _ctx.name,
      onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.callback && _ctx.callback(...args)), ["enter"])),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeIsDirty && _ctx.changeIsDirty(...args))),
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
      onFocusout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.validate && _ctx.validate(...args)))
    }, null, 40, _hoisted_5), [
      [_vModelText, _ctx.value]
    ])
  ]))
}