
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import Button from '@/processes/common/components/atoms/Button/index.vue';
import Input from '@/processes/common/components/atoms/Input/index.vue';
import { BARCODE_HAS_MANY_CHAR, NOT_EMPTY_BARCODE } from '@/processes/common/rules';
import axiosWrapper from '@/processes/common/api';
import { TSD_MENU_URL } from '@/processes/common/api/routes';

export default defineComponent({
  name: 'Demonstration Page',
  components: { Input, Button },
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      validatorBarcode: { ...BARCODE_HAS_MANY_CHAR, ...NOT_EMPTY_BARCODE }
    };
  },
  methods: {
    logBtn() {
      console.log('abra');
    },
    logError(data : Array<Error>) {
      console.log('Error here', data);
    }
  },
  async mounted() {
    // Примеры вызовов стора и обработчика дат
    console.log('dateSample', this.$dayjs());
    console.log('store', this.$store.state.user);
    // Пример вызова аксиоса
    try {
      const response = await axiosWrapper({
        ...TSD_MENU_URL,
        data: {
          menu: 'index'
        }
      });
    } catch (e) {
      console.error('error', e);
    }
  }
});
