import axios, { AxiosRequestConfig } from 'axios';

/**
 * @param data Параметры для аксиоаса
 * @return Promise Возвращает Promise в котором содержатся данные ответа сервера, без статуса,
 * если произошла ошибка вернется Promise c ошибкой
 */
const axiosWrapper = async (data : AxiosRequestConfig) : Promise<any> => {
  try {
    const { data: res } = await axios({
      ...data,
      headers: { 'Access-Control-Allow-Origin': '*' }
    });
    return res;
  } catch (err) {
    console.log('abra', 'Здесь будет логирование в сентри, чтобы был общий хендлер');
    throw err;
  }
};

export default axiosWrapper;
